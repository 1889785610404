
import { defineComponent } from "vue"
import github from "@/components/github.vue"

export default defineComponent({
  name: "Home",
  components: {
    github,
  },
})
