
import { defineComponent } from "vue"

import dayjs from "dayjs"

interface Props {
  dataArray: [{
    login: string,
    avatar_url: string,
    html_url: string,
    name: string,
    company: string,
    blog: string,
    location: string,
    email: string | null,
    hireable: boolean,
    bio: string,
    twitter_username: string | null,
    public_repos: number,
    public_gists: number,
    followers: number,
    following: number,
    created_at: string,
    updated_at: string,
  }, {
    type: string,
    repo: {
      name: string,
    },
    payload: {
      commits?: {
        sha: string,
        message: string,
        url: string,
      }[],
    },
  }[]],
  lastActivity: number,
}

export default defineComponent({
  name: "hspWhois",
  data(): Props {
    return {
      dataArray: [{
        login: "mbledkowski",
        avatar_url: "https://avatars.githubusercontent.com/u/23295125",
        html_url: "https://github.com/mbledkowski",
        name: "Maciej Błędkowski",
        company: "N/A",
        blog: "mble.dk",
        location: "Gdańsk, Poland",
        email: null,
        hireable: true,
        bio: "Front-end developer",
        twitter_username: null,
        public_repos: 46,
        public_gists: 6,
        followers: 11,
        following: 120,
        created_at: "2016-11-06T15:05:52Z",
        updated_at: "2021-12-23T22:25:55Z",
      }, [{
        type: "",
        repo: {
          name: "",
        },
        payload: {
        },
      }]],
      lastActivity: -1,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const profile = async () => {
        await fetch("https://api.github.com/user/23295125").then(res => res.json()).then(res => this.dataArray[0] = res)
        this.lastActivity = dayjs().diff(dayjs(this.dataArray[0].updated_at), 'minutes')
      }
      fetch("https://api.github.com/user/23295125/events").then(res => res.json()).then(res => this.dataArray[1] = res)
      profile()
    },
  },
})
